import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss";

const Header = ({ siteTitle }) => (
  <header id="site-header">
    <blockquote>
      <div>
        <p>I fight<br/>for the<br/>users!</p>
        <footer><cite>
          <a href="https://disney.fandom.com/wiki/Tron_(character)"
             title="Character of Tron at Fandom">Tron</a>
        </cite></footer>
      </div>
    </blockquote>
    <div className="profile">
      <h1>
        I am Simo Santala,<br/><strong>a designer–developer</strong><br/>who fights for the users.
      </h1>
      <p className="tagline"></p>
      <div className="contact">
        You can reach me on <a href="https://www.linkedin.com/in/santalas/" title="Simo Santala at LinkedIn">LinkedIn</a>.
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
